// import api from "@/services/Api";

export default {
  async getSingleProduct(id, accessToken, source, sourceName) {
    try {
      const baseURL = process.env.VUE_APP_BASEURL;
      const response = await fetch(`${baseURL}/sellers/product/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Source: source,
          "Source-Name": sourceName,
        },
      });
      //   const response = await fetch.get(`/sellers/product/${id}`, {
      //     headers: {
      //       Authorization: `Bearer ${accessToken}`,
      //       Source: source,
      //       "Source-Name": sourceName,
      //     },
      //   });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
